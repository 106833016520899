<div class="app-query-options">
    <div>
        <input type="text" ngxDaterangepickerMd [ranges]="ranges" [alwaysShowCalendars]="true" opens="center"
               [(ngModel)]="selected" class="form-control" placeholder="Desde -> Hasta"
               (datesUpdated)="datesUpdated($event)" [showClearButton]="true" />
    </div>
    <div>
        <ng-select [items]="filtrosSelect$ | async" bindLabel="nombre" [trackByFn]="trackByFn" [typeahead]="typeahead$"
                   [(ngModel)]="selectedOrganizations" (change)="onFiltrosChange($event)"
                   placeholder="Escriba para establecer filtros" [multiple]="true">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                {{ item | label }}
                <b>
                    ({{ item.type }})
                </b>
            </ng-template>
            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                {{ item | label }}
                <b>
                    ({{ item.type }})
                </b>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </ng-template>
        </ng-select>
    </div>
</div>