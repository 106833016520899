<ng-container *ngIf="concept$ | async as concept">

    <app-navbar-detail-component [concept]="concept">
        <span ngbDropdown>
            <button ngbDropdownToggle class="btn btn-success dropdown-toggle btn-sm mr-3" type="button">
                Graficos
            </button>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem (click)="showGrafico = 'burbujas'">
                    Burbujas
                </button>
                <button ngbDropdownItem (click)="showGrafico = 'treemap'">
                    Treemap
                </button>
            </div>
        </span>

        <span ngbDropdown>
            <button ngbDropdownToggle class="btn btn-info dropdown-toggle btn-sm" type="button">
                {{ semanticCluster$ | async }}
            </button>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem (click)="semanticCluster.next('trastorno')">
                    Trastorno
                </button>
                <button ngbDropdownItem (click)="semanticCluster.next('hallazgo')">
                    Hallazgo
                </button>
                <button ngbDropdownItem (click)="semanticCluster.next('procedimiento')">
                    Procedimiento
                </button>
                <button ngbDropdownItem (click)="semanticCluster.next('producto')">
                    Producto
                </button>
            </div>
        </span>

        <span ngbDropdown class="ml-3">
            <button ngbDropdownToggle class="btn btn-info dropdown-toggle btn-sm" type="button">
                Asociar según: {{ tipoAsociacionCluster$ | async }}
            </button>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem (click)="tipoAsociacionCluster.next('paciente')">
                    Paciente
                </button>
                <button ngbDropdownItem (click)="tipoAsociacionCluster.next('prestacion')">
                    Prestacion
                </button>
            </div>
        </span>

    </app-navbar-detail-component>

    <ng-container *ngIf="asociados$ | async as asociados">

        <ui-table [values]="asociados" *ngIf="!loading"></ui-table>

        <div class="fullscreen" *ngIf="showGrafico">
            <app-navbar-detail-component [concept]="concept">
                <span ngbDropdown class="mr-3">
                    <button ngbDropdownToggle class="btn btn-info dropdown-toggle btn-sm" type="button">
                        {{ semanticCluster$ | async }}
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button ngbDropdownItem (click)="semanticCluster.next('trastorno')">
                            Trastorno
                        </button>
                        <button ngbDropdownItem (click)="semanticCluster.next('hallazgo')">
                            Hallazgo
                        </button>
                        <button ngbDropdownItem (click)="semanticCluster.next('procedimiento')">
                            Procedimiento
                        </button>
                        <button ngbDropdownItem (click)="semanticCluster.next('producto')">
                            Producto
                        </button>
                    </div>
                </span>
                <button class="btn btn-outline-danger btn-sm" type="button" (click)="showGrafico = null">
                    X
                </button>
            </app-navbar-detail-component>
            <app-bubble-charts-component [data]="asociados" *ngIf="showGrafico==='burbujas'">
            </app-bubble-charts-component>
            <app-treemap-chart-component [data]="asociados" *ngIf="showGrafico==='treemap'">
            </app-treemap-chart-component>
        </div>


    </ng-container>
</ng-container>