/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./maps-stats.view.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../concept-detail/navbar-detail/navbar-detail.component.ngfactory";
import * as i3 from "../concept-detail/navbar-detail/navbar-detail.component";
import * as i4 from "../../../../../../node_modules/@agm/core/agm-core.ngfactory";
import * as i5 from "@agm/core";
import * as i6 from "@angular/common";
import * as i7 from "./maps-stats.view";
import * as i8 from "../../services/snomed.service";
import * as i9 from "../../services/query-filter.service";
import * as i10 from "@angular/router";
var styles_AppMapsStatsView = [i0.styles];
var RenderType_AppMapsStatsView = i1.ɵcrt({ encapsulation: 0, styles: styles_AppMapsStatsView, data: {} });
export { RenderType_AppMapsStatsView as RenderType_AppMapsStatsView };
function View_AppMapsStatsView_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "fullscreen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "app-navbar-detail-component", [], null, null, null, i2.View_AppNavbarDetailComponent_0, i2.RenderType_AppNavbarDetailComponent)), i1.ɵdid(3, 49152, null, 0, i3.AppNavbarDetailComponent, [], { concept: [0, "concept"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "button", [["class", "btn btn-success mr-1 btn-sm"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleEfectores() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" EFECTORES "])), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "button", [["class", "btn btn-outline-danger btn-sm"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cerrar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" X "])), (_l()(), i1.ɵeld(8, 0, null, null, 13, "div", [["style", "height:calc(100% - 58px); width:100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 12, "agm-map", [["style", "height: 100%;"]], [[2, "sebm-google-map-container", null]], [[null, "mapReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mapReady" === en)) {
        var pd_0 = (_co.onMap($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_AgmMap_0, i4.RenderType_AgmMap)), i1.ɵprd(4608, null, i5.CircleManager, i5.CircleManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.DataLayerManager, i5.DataLayerManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.MarkerManager, i5.MarkerManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.InfoWindowManager, i5.InfoWindowManager, [i5.GoogleMapsAPIWrapper, i1.NgZone, i5.MarkerManager]), i1.ɵprd(4608, null, i5.KmlLayerManager, i5.KmlLayerManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.LayerManager, i5.LayerManager, [i5.GoogleMapsAPIWrapper]), i1.ɵprd(4608, null, i5.PolygonManager, i5.PolygonManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.PolylineManager, i5.PolylineManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i5.RectangleManager, i5.RectangleManager, [i5.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(512, null, i5.GoogleMapsAPIWrapper, i5.GoogleMapsAPIWrapper, [i5.MapsAPILoader, i1.NgZone]), i1.ɵprd(512, null, i5.ɵa, i5.ɵa, [i5.MapsAPILoader]), i1.ɵdid(21, 770048, null, 0, i5.AgmMap, [i1.ElementRef, i5.GoogleMapsAPIWrapper, i5.ɵa, i1.NgZone], { longitude: [0, "longitude"], latitude: [1, "latitude"], zoom: [2, "zoom"] }, { mapReady: "mapReady" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.lng; var currVal_4 = _co.lat; var currVal_5 = _co.zoom; _ck(_v, 21, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showOrganizaciones; _ck(_v, 4, 0, currVal_1); var currVal_2 = true; _ck(_v, 9, 0, currVal_2); }); }
export function View_AppMapsStatsView_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppMapsStatsView_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.concept$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppMapsStatsView_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-maps-stats-view", [], null, null, null, View_AppMapsStatsView_0, RenderType_AppMapsStatsView)), i1.ɵdid(1, 49152, null, 0, i7.AppMapsStatsView, [i8.SnomedAPI, i9.QueryOptionsService, i10.ActivatedRoute, i6.Location], null, null)], null, null); }
var AppMapsStatsViewNgFactory = i1.ɵccf("app-maps-stats-view", i7.AppMapsStatsView, View_AppMapsStatsView_Host_0, {}, {}, []);
export { AppMapsStatsViewNgFactory as AppMapsStatsViewNgFactory };
