/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../libs/ui/src/lib/badge/badge.component.ngfactory";
import * as i2 from "../../../../../../libs/ui/src/lib/badge/badge.component";
import * as i3 from "@angular/common";
import * as i4 from "./concept-count-pill.component";
import * as i5 from "../../services/snomed.service";
var styles_ConceptCountPillComponent = [];
var RenderType_ConceptCountPillComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConceptCountPillComponent, data: {} });
export { RenderType_ConceptCountPillComponent as RenderType_ConceptCountPillComponent };
function View_ConceptCountPillComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "mr-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "ui-badge", [["type", "info"]], null, null, null, i1.View_BadgeComponent_0, i1.RenderType_BadgeComponent)), i0.ɵdid(2, 49152, null, 0, i2.BadgeComponent, [], { label: [0, "label"], type: [1, "type"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.total; var currVal_1 = "info"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ConceptCountPillComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "ui-badge", [["type", "warning"]], null, null, null, i1.View_BadgeComponent_0, i1.RenderType_BadgeComponent)), i0.ɵdid(2, 49152, null, 0, i2.BadgeComponent, [], { label: [0, "label"], type: [1, "type"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.exact; var currVal_1 = "warning"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ConceptCountPillComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConceptCountPillComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConceptCountPillComponent_3)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.ngIf.total > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.ngIf.total > 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ConceptCountPillComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_ConceptCountPillComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.snomed.stats(_co.concept.conceptId))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConceptCountPillComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "concept-count-pill", [], null, null, null, View_ConceptCountPillComponent_0, RenderType_ConceptCountPillComponent)), i0.ɵdid(1, 49152, null, 0, i4.ConceptCountPillComponent, [i5.SnomedAPI], null, null)], null, null); }
var ConceptCountPillComponentNgFactory = i0.ɵccf("concept-count-pill", i4.ConceptCountPillComponent, View_ConceptCountPillComponent_Host_0, { concept: "concept" }, {}, []);
export { ConceptCountPillComponentNgFactory as ConceptCountPillComponentNgFactory };
