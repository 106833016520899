import { of } from 'rxjs';
import { Server } from '../../../../../libs/snomed/src/lib/services/server.service';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../libs/snomed/src/lib/services/server.service";
export class UserService {
    constructor(http) {
        this.http = http;
    }
    registerConcepto(concepto) {
        if (environment.production) {
            return this.http.post('/user/frecuentes', concepto);
        }
        else {
            return of(null);
        }
    }
    misFrecuentes() {
        if (environment.production) {
            return this.http.get('/user/frecuentes');
        }
        else {
            return of([]);
        }
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.Server)); }, token: UserService, providedIn: "root" });
