import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class AppService {
    constructor() {
        this.navbar = new BehaviorSubject(true);
        this.navbar$ = this.navbar.asObservable();
    }
    setNavbarState(value) {
        this.navbar.next(value);
    }
    tootleNavbarState() {
        const value = !this.navbar.getValue();
        this.navbar.next(value);
    }
    get navbarState() {
        return this.navbar$;
    }
}
AppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppService_Factory() { return new AppService(); }, token: AppService, providedIn: "root" });
