/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./home.view";
var styles_AppHomeView = [];
var RenderType_AppHomeView = i0.ɵcrt({ encapsulation: 2, styles: styles_AppHomeView, data: {} });
export { RenderType_AppHomeView as RenderType_AppHomeView };
export function View_AppHomeView_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Selecciona un concepto para ver datos. "]))], null, null); }
export function View_AppHomeView_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-home-view", [], null, null, null, View_AppHomeView_0, RenderType_AppHomeView)), i0.ɵdid(1, 49152, null, 0, i1.AppHomeView, [], null, null)], null, null); }
var AppHomeViewNgFactory = i0.ɵccf("app-home-view", i1.AppHomeView, View_AppHomeView_Host_0, {}, {}, []);
export { AppHomeViewNgFactory as AppHomeViewNgFactory };
