/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./circle-node.svg";
var styles_CircleNodeComponent = [];
var RenderType_CircleNodeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CircleNodeComponent, data: {} });
export { RenderType_CircleNodeComponent as RenderType_CircleNodeComponent };
function View_CircleNodeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:circle", [["fill", "white"], ["r", "20"], ["stroke", "black"], ["stroke-with", "2"]], [[1, "cx", 0], [1, "cy", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.x; var currVal_1 = _co.y; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CircleNodeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:circle", [["fill", "black"], ["r", "10"], ["stroke", "black"], ["stroke-with", "2"]], [[1, "cx", 0], [1, "cy", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.x; var currVal_1 = _co.y; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CircleNodeComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:circle", [["fill", "white"], ["r", "20"], ["stroke", "black"], ["stroke-with", "2"]], [[1, "cx", 0], [1, "cy", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.x; var currVal_1 = _co.y; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.x - 7); var currVal_3 = (_co.y - 5); var currVal_4 = (_co.x + 7); var currVal_5 = (_co.y - 5); _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = (_co.x - 7); var currVal_7 = _co.y; var currVal_8 = (_co.x + 7); var currVal_9 = _co.y; _ck(_v, 3, 0, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = (_co.x - 7); var currVal_11 = (_co.y + 5); var currVal_12 = (_co.x + 7); var currVal_13 = (_co.y + 5); _ck(_v, 4, 0, currVal_10, currVal_11, currVal_12, currVal_13); }); }
function View_CircleNodeComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:circle", [["fill", "white"], ["r", "20"], ["stroke", "black"], ["stroke-with", "2"]], [[1, "cx", 0], [1, "cy", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.x; var currVal_1 = _co.y; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.x - 7); var currVal_3 = (_co.y - 8); var currVal_4 = (_co.x + 7); var currVal_5 = (_co.y - 8); _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = (_co.x - 7); var currVal_7 = (_co.y + 3); var currVal_8 = (_co.x + 7); var currVal_9 = (_co.y + 3); _ck(_v, 3, 0, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = (_co.x - 6); var currVal_11 = (_co.y - 8); var currVal_12 = (_co.x - 6); var currVal_13 = (_co.y + 3); _ck(_v, 4, 0, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = (_co.x - 7); var currVal_15 = (_co.y + 7); var currVal_16 = (_co.x + 7); var currVal_17 = (_co.y + 7); _ck(_v, 5, 0, currVal_14, currVal_15, currVal_16, currVal_17); }); }
function View_CircleNodeComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:circle", [["fill", "white"], ["r", "20"], ["stroke", "black"], ["stroke-with", "2"]], [[1, "cx", 0], [1, "cy", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:line", [["stroke", "black"], ["stroke-width", "2"]], [[1, "x1", 0], [1, "y1", 0], [1, "x2", 0], [1, "y2", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.x; var currVal_1 = _co.y; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.x - 7); var currVal_3 = (_co.y - 8); var currVal_4 = (_co.x + 7); var currVal_5 = (_co.y - 8); _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = (_co.x - 7); var currVal_7 = (_co.y + 3); var currVal_8 = (_co.x + 7); var currVal_9 = (_co.y + 3); _ck(_v, 3, 0, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = (_co.x + 6); var currVal_11 = (_co.y - 8); var currVal_12 = (_co.x + 6); var currVal_13 = (_co.y + 3); _ck(_v, 4, 0, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = (_co.x - 7); var currVal_15 = (_co.y + 7); var currVal_16 = (_co.x + 7); var currVal_17 = (_co.y + 7); _ck(_v, 5, 0, currVal_14, currVal_15, currVal_16, currVal_17); }); }
export function View_CircleNodeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CircleNodeComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CircleNodeComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CircleNodeComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CircleNodeComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CircleNodeComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "attribute-group"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.type === "conjunction"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.type === "equivalent"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.type === "subsumed-by"); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.type === "subsumes"); _ck(_v, 9, 0, currVal_4); }, null); }
export function View_CircleNodeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["circleNode", ""]], null, null, null, View_CircleNodeComponent_0, RenderType_CircleNodeComponent)), i0.ɵdid(1, 49152, null, 0, i2.CircleNodeComponent, [i0.ChangeDetectorRef, i0.ElementRef], null, null)], null, null); }
var CircleNodeComponentNgFactory = i0.ɵccf("[circleNode]", i2.CircleNodeComponent, View_CircleNodeComponent_Host_0, { x: "x", y: "y", type: "type" }, {}, []);
export { CircleNodeComponentNgFactory as CircleNodeComponentNgFactory };
