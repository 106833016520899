import { SnomedAPI } from '../../services/snomed.service';
import { Subject, of } from 'rxjs';
import { mergeObject, distincObject, cache } from '../../operators';
import { switchMap, pluck, map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../../services/snomed.service";
import * as i2 from "@angular/router";
export class SnomedSearchService {
    constructor(snomed, route) {
        this.snomed = snomed;
        this.route = route;
        this.searchParams = new Subject();
        this.searchParams$ = this.searchParams.asObservable();
        this.language = 'es';
        this.searchResult$ = this.searchParams$.pipe(mergeObject(), distincObject(), switchMap((params) => {
            if (params.search && params.search.length > 0) {
                return this.searchRequest(params);
            }
            else {
                return of({ items: [], buckets: { semanticTags: {} } });
            }
        }), map(result => {
            return {
                items: result.items,
                semanticTags: result.buckets.semanticTags
            };
        }), startWith({ items: [], semanticTags: {} }), cache());
        this.route.queryParamMap.subscribe(params => {
            this.language = params.get('language');
        });
    }
    getSearchParams(name) {
        return this.searchParams$.pipe(pluck(name));
    }
    getResult(name) {
        return this.searchResult$.pipe(pluck(name));
    }
    search(query) {
        this.searchParams.next(query);
    }
    searchRequest(query) {
        const params = {
            term: query.search,
            limit: 20,
            language: this.language
        };
        if (query.semanticTag && query.semanticTag.length > 0) {
            params.semanticTag = query.semanticTag;
        }
        return this.snomed.descriptions(params);
    }
}
SnomedSearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnomedSearchService_Factory() { return new SnomedSearchService(i0.ɵɵinject(i1.SnomedAPI), i0.ɵɵinject(i2.ActivatedRoute)); }, token: SnomedSearchService, providedIn: "root" });
