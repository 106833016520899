/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./connect-element.svg";
var styles_ConnectElementComponent = [];
var RenderType_ConnectElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConnectElementComponent, data: {} });
export { RenderType_ConnectElementComponent as RenderType_ConnectElementComponent };
export function View_ConnectElementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:g", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:polyline", [["fill", "none"], ["stroke", "black"], ["stroke-width", "2"]], [[1, "points", 0], [1, "marker-end", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.points; var currVal_1 = (("url(#" + _co.endMarker) + ")"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_ConnectElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["connectElement", ""]], null, null, null, View_ConnectElementComponent_0, RenderType_ConnectElementComponent)), i0.ɵdid(1, 49152, null, 0, i1.ConnectElementComponent, [], null, null)], null, null); }
var ConnectElementComponentNgFactory = i0.ɵccf("[connectElement]", i1.ConnectElementComponent, View_ConnectElementComponent_Host_0, { from: "from", to: "to", sideFrom: "sideFrom", sideTo: "sideTo", endMarker: "endMarker" }, {}, []);
export { ConnectElementComponentNgFactory as ConnectElementComponentNgFactory };
