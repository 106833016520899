/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./demografia-table.component";
var styles_DemografiaTableComponent = [".click[_ngcontent-%COMP%]:hover {\n                text-decoration: underline;\n                cursor: pointer;    \n            }"];
var RenderType_DemografiaTableComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DemografiaTableComponent, data: {} });
export { RenderType_DemografiaTableComponent as RenderType_DemografiaTableComponent };
function View_DemografiaTableComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_DemografiaTableComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [], [[2, "click", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emit("masculino", _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isClick; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.tableDemografia[0][_v.context.$implicit])); _ck(_v, 1, 0, currVal_1); }); }
function View_DemografiaTableComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [], [[2, "click", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emit("femenino", _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isClick; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.tableDemografia[1][_v.context.$implicit])); _ck(_v, 1, 0, currVal_1); }); }
function View_DemografiaTableComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.tableDemografia[2][_v.context.$implicit])); _ck(_v, 1, 0, currVal_0); }); }
export function View_DemografiaTableComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 35, "div", [["class", "row m-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 34, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 33, "table", [["class", "table table-striped table-bordered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sexo"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DemografiaTableComponent_1)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Total"])), (_l()(), i0.ɵeld(12, 0, null, null, 24, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Masculino"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DemografiaTableComponent_2)), i0.ɵdid(17, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(18, 0, null, null, 2, "td", [], [[2, "click", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emit("masculino", (0 - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(19, null, [" ", " "])), i0.ɵppd(20, 1), (_l()(), i0.ɵeld(21, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Femenino"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DemografiaTableComponent_3)), i0.ɵdid(25, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(26, 0, null, null, 2, "td", [], [[2, "click", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emit("femenino", (0 - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(27, null, [" ", " "])), i0.ɵppd(28, 1), (_l()(), i0.ɵeld(29, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Total"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DemografiaTableComponent_4)), i0.ɵdid(33, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(34, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(35, null, [" ", " "])), i0.ɵppd(36, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.escalas[_co.rangoEtario]; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.indices; _ck(_v, 17, 0, currVal_1); var currVal_4 = _co.indices; _ck(_v, 25, 0, currVal_4); var currVal_7 = _co.indices; _ck(_v, 33, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isClick; _ck(_v, 18, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 19, 0, _ck(_v, 20, 0, i0.ɵnov(_v, 0), _co.tableDemografia[0][_co.numCols])); _ck(_v, 19, 0, currVal_3); var currVal_5 = _co.isClick; _ck(_v, 26, 0, currVal_5); var currVal_6 = i0.ɵunv(_v, 27, 0, _ck(_v, 28, 0, i0.ɵnov(_v, 0), _co.tableDemografia[1][_co.numCols])); _ck(_v, 27, 0, currVal_6); var currVal_8 = i0.ɵunv(_v, 35, 0, _ck(_v, 36, 0, i0.ɵnov(_v, 0), _co.tableDemografia[2][_co.numCols])); _ck(_v, 35, 0, currVal_8); }); }
export function View_DemografiaTableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-demografia-table", [], null, null, null, View_DemografiaTableComponent_0, RenderType_DemografiaTableComponent)), i0.ɵdid(1, 638976, null, 0, i2.DemografiaTableComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DemografiaTableComponentNgFactory = i0.ɵccf("app-demografia-table", i2.DemografiaTableComponent, View_DemografiaTableComponent_Host_0, { data: "data", rangoEtario: "rangoEtario", metrica: "metrica", isClick: "isClick" }, { select: "select" }, []);
export { DemografiaTableComponentNgFactory as DemografiaTableComponentNgFactory };
