/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./bubble-charts.component";
var styles_AppBubbleChartsView = [];
var RenderType_AppBubbleChartsView = i0.ɵcrt({ encapsulation: 2, styles: styles_AppBubbleChartsView, data: {} });
export { RenderType_AppBubbleChartsView as RenderType_AppBubbleChartsView };
export function View_AppBubbleChartsView_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { contenedor: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["contenedor", 1]], null, 0, "div", [["style", "height: calc(100% - 58px);;  overflow: hidden; background-color: white;"]], null, null, null, null, null))], null, null); }
export function View_AppBubbleChartsView_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-bubble-charts-component", [], null, null, null, View_AppBubbleChartsView_0, RenderType_AppBubbleChartsView)), i0.ɵdid(1, 4767744, null, 0, i1.AppBubbleChartsView, [], null, null)], null, null); }
var AppBubbleChartsViewNgFactory = i0.ɵccf("app-bubble-charts-component", i1.AppBubbleChartsView, View_AppBubbleChartsView_Host_0, { data: "data" }, {}, []);
export { AppBubbleChartsViewNgFactory as AppBubbleChartsViewNgFactory };
