/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../libs/ui/src/lib/table/table.component.ngfactory";
import * as i2 from "../../../../../../libs/ui/src/lib/table/table.component";
import * as i3 from "@angular/common";
import * as i4 from "../concept-detail/navbar-detail/navbar-detail.component.ngfactory";
import * as i5 from "../concept-detail/navbar-detail/navbar-detail.component";
import * as i6 from "./localidades-stats.view";
import * as i7 from "../../services/snomed.service";
import * as i8 from "../../services/query-filter.service";
import * as i9 from "@angular/router";
var styles_AppLocalidadesStatsView = [];
var RenderType_AppLocalidadesStatsView = i0.ɵcrt({ encapsulation: 2, styles: styles_AppLocalidadesStatsView, data: {} });
export { RenderType_AppLocalidadesStatsView as RenderType_AppLocalidadesStatsView };
function View_AppLocalidadesStatsView_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ui-table", [], null, null, null, i1.View_TableSetComponent_0, i1.RenderType_TableSetComponent)), i0.ɵdid(1, 4308992, null, 0, i2.TableSetComponent, [], { values: [0, "values"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppLocalidadesStatsView_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppLocalidadesStatsView_3)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppLocalidadesStatsView_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-navbar-detail-component", [], null, null, null, i4.View_AppNavbarDetailComponent_0, i4.RenderType_AppNavbarDetailComponent)), i0.ɵdid(2, 49152, null, 0, i5.AppNavbarDetailComponent, [], { concept: [0, "concept"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AppLocalidadesStatsView_2)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.localidades$)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AppLocalidadesStatsView_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_AppLocalidadesStatsView_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.concept$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppLocalidadesStatsView_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-localidades-stats-view", [], null, null, null, View_AppLocalidadesStatsView_0, RenderType_AppLocalidadesStatsView)), i0.ɵdid(1, 49152, null, 0, i6.AppLocalidadesStatsView, [i7.SnomedAPI, i8.QueryOptionsService, i9.ActivatedRoute], null, null)], null, null); }
var AppLocalidadesStatsViewNgFactory = i0.ɵccf("app-localidades-stats-view", i6.AppLocalidadesStatsView, View_AppLocalidadesStatsView_Host_0, {}, {}, []);
export { AppLocalidadesStatsViewNgFactory as AppLocalidadesStatsViewNgFactory };
