<ng-container *ngIf="concept$ | async as concept">

    <app-navbar-detail-component [concept]="concept">
        <button class="btn btn-xs btn-info" type="button" (click)="getListado()" *ngIf="showListado">
            Listado Pacientes
        </button>
    </app-navbar-detail-component>

    <!-- <ui-modal #modal>
        <iframe #frame style="height:600px;width:80vw;border:none"
                src="https://sanddance.js.org/embed/v1/sanddance-embed.html"></iframe>
    </ui-modal> -->

    <div class="ml-5 mr-5 d-flex justify-content-between">
        <h5>
            Rango Etario
        </h5>

        <!-- <button class="btn btn-success" type="button" (click)="modal.show();verSandDance()">
            VER GRAFICO
        </button> -->

        <div ngbDropdown>
            <button ngbDropdownToggle class="btn btn-xs btn-info dropdown-toggle" type="button">
                Metrica
            </button>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem (click)="setMetrica('count')">
                    Registros
                </button>
                <button ngbDropdownItem (click)="setMetrica('unique')">
                    Paciente Únicos
                </button>
                <button ngbDropdownItem (click)="setMetrica('value')" *ngIf="concept.isNumeric">
                    Promedio
                </button>
            </div>
        </div>

        <div ngbDropdown>
            <button ngbDropdownToggle class="btn btn-xs btn-info dropdown-toggle" type="button">
                Cambiar escala
            </button>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button ngbDropdownItem (click)="changeRangoEtario('nacional')">
                    Rango Etario Nacional
                </button>
                <button ngbDropdownItem (click)="changeRangoEtario('provincial')">
                    Rango Etario Provincial
                </button>
            </div>
        </div>
    </div>

    <app-demografia-table [data]="data$ | async" [metrica]="metrica$ | async" [rangoEtario]="rango$ | async"
                          (select)="onClick($event)" [isClick]="concept.isNumeric">
    </app-demografia-table>

    <div class="d-flex">
        <ng-container *ngIf="masculinoBarChart$ |async as dataM">
            <ng-container *ngIf="femeninoBarChart$ |async as dataF">
                <plotly-plot [data]="[dataM, dataF]" [layout]="graph.layout"></plotly-plot>
            </ng-container>
            <!-- <plotly-plot [data]="data" [layout]="graph.layout"></plotly-plot> -->
        </ng-container>

    </div>


    <div class="fullscreen" *ngIf="dataRaw$">
        <app-navbar-detail-component [concept]="concept">
            <button class="btn btn-outline-danger btn-sm" type="button" (click)="onCloseFullscreen()">
                X
            </button>
        </app-navbar-detail-component>

        <div class="d-flex">
            <plotly-plot [data]="boxplotData$ | async" [layout]="graph.boxplot"></plotly-plot>
            <div class="list-group" *ngIf="conceptosSelected | async as selecteds">
                <button class="list-group-item list-group-item-action" [class.active]="selecteds[c]"
                        *ngFor="let c of conceptos$ | async " (click)="onItemClick(c)">
                    {{ c }}
                </button>
            </div>

        </div>


    </div>
</ng-container>