import { Component, ViewEncapsulation } from '@angular/core';
import { getConceptOperator, SnomedAPI } from '../../services/snomed.service';
import { QueryOptionsService } from '../../services/query-filter.service';
import { combineLatest, forkJoin, BehaviorSubject } from 'rxjs';
import { pluck, switchMap, map, startWith, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { cache, combineDataset } from '../../operators';

@Component({
    selector: 'app-llm-view',
    templateUrl: './llm.view.html',
    encapsulation: ViewEncapsulation.None,
    styles: [`

        .response {
            padding: 8px;
            background-color: aliceblue;
        }

        .response table {
            border-collapse: collapse;
        }

        .response tr, .response td {
            border: 1px solid black;
            padding: 8px;
        }
    
    `]
})
export class AppLLMView {
    public showGrafico: string = null;
    public loading = false;
    public concept$; 

    public text = "cual es la edad promedio en función del tiempo?";
    public response;
    public rawResponse;

    constructor(
        private snomed: SnomedAPI,
        private qf: QueryOptionsService,
        private activeRoute: ActivatedRoute
    ) {
        this.concept$ = getConceptOperator(this.activeRoute).pipe(
            switchMap(([conceptId, language]) => this.snomed.concept(conceptId, language)),
            cache()
        );
 
    }

    onChange(event) {

    }

    getListado() {
        this.concept$.pipe(
            switchMap((c: any) => {
                return this.snomed.llm(c.conceptId, this.text)
            }) 
        ).subscribe((m) => { 
            this.response = m.texto;
            this.rawResponse = m.rawResponse;
        })
    }
 
}

