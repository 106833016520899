/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./badge.component";
var styles_BadgeComponent = [];
var RenderType_BadgeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BadgeComponent, data: {} });
export { RenderType_BadgeComponent as RenderType_BadgeComponent };
function View_BadgeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "ui-badge-pill ui-badge-pill-", _co.type, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.pill; _ck(_v, 1, 0, currVal_1); }); }
export function View_BadgeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BadgeComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.pill !== null); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "ui-badge ui-badge-", _co.type, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 1, 0, currVal_1); }); }
export function View_BadgeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ui-badge", [], null, null, null, View_BadgeComponent_0, RenderType_BadgeComponent)), i0.ɵdid(1, 49152, null, 0, i2.BadgeComponent, [], null, null)], null, null); }
var BadgeComponentNgFactory = i0.ɵccf("ui-badge", i2.BadgeComponent, View_BadgeComponent_Host_0, { label: "label", pill: "pill", type: "type" }, {}, []);
export { BadgeComponentNgFactory as BadgeComponentNgFactory };
