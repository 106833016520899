import { Component } from '@angular/core';

@Component({
    selector: 'app-home-view',
    templateUrl: './home.view.html'
})
export class AppHomeView {

    constructor() { }

}
