<div style="overflow-y: scroll; overflow-x: hidden; height: 100%;">
    <div class="p-1">
        <input uiText [(ngModel)]="text" (write)="onChange($event)" debounce="400" [distinct]="true">
    </div>
    <div class="p-1">
        <ng-select [items]="(semantics$ | async) | keyvalue: valueAscOrder" bindLabel="key"
                   placeholder="Filtro por semanticTags" (change)="onSemtagClick($event)" (clear)="removeSemTag()">

            <ng-template ng-label-tmp let-item="item">
                {{item.key}} ({{ item.value }})
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                {{item.key}} ({{ item.value }})
            </ng-template>
        </ng-select>
    </div>

    <!-- <div class="contenedor-semantic-tags pt-2">
        <div *ngIf="semTagSelected$ | async as semTagSelected; else sinDatos">
            <ui-badge [label]="semTagSelected" pill="X" (click)="removeSemTag()"></ui-badge>
        </div>
        <ng-template #sinDatos>
            <ng-container *ngFor="let semTag of (semantics$ | async) | keyvalue: valueAscOrder">
                <div class="ml-2 mb-2" style="cursor: pointer;" (click)="onSemtagClick(semTag.key)">
                    <ui-badge [label]="semTag.key" [pill]="semTag.value"></ui-badge>
                </div>
            </ng-container>
        </ng-template>
    </div> -->
    <ng-container *ngIf="(results$ | async) as results">
        <ul class="list-group m-1" *ngIf="results.length > 0">
            <li class="list-group-item d-flex justify-content-between align-items-center"
                [class.selected]="selectedConcept === searchItem.concept.conceptId" *ngFor="let searchItem of results"
                (click)="onClick(searchItem.concept)">
                <div class="d-flex flex-row align-items-center">
                    <i class="adi adi-{{ searchItem.concept | semtag }} mr-2"></i>
                    <concept-definition-status-icon [concept]="searchItem.concept">
                    </concept-definition-status-icon>
                    <div class="titulo-item">
                        <b> {{ searchItem.term }} </b>
                        <small> {{ searchItem.concept.conceptId }} | <i> {{ searchItem.concept | semtag }} </i> </small>
                    </div>
                </div>
                <concept-count-pill class="d-flex align-center" [concept]="searchItem.concept"></concept-count-pill>
            </li>
        </ul>
        <div *ngIf="results.length === 0 && text.length > 0">
            <h5 *ngIf="">
                No hay resultados
            </h5>
        </div>
        <ng-container *ngIf="results.length === 0 && (!text || text.length === 0)">
            <ng-container *ngIf="frecuentes$ | async as frecuentes">


                <ul class="list-group m-1" *ngIf="frecuentes.length > 0">
                    <li class="list-group-item d-flex justify-content-between align-items-center"
                        [class.selected]="selectedConcept === searchItem.concepto.conceptId"
                        *ngFor="let searchItem of frecuentes" (click)="onClick(searchItem.concepto)">
                        <div class="d-flex flex-row align-items-center">
                            <i class="adi adi-{{ searchItem.concepto | semtag }} mr-2"></i>
                            <concept-definition-status-icon [concept]="searchItem.concepto">
                            </concept-definition-status-icon>
                            <div class="titulo-item">
                                <b> {{ searchItem.concepto.pt.term }} </b>
                                <small> {{ searchItem.concepto.conceptId }} | <i> {{ searchItem.concepto | semtag }}
                                    </i> </small>
                            </div>
                        </div>
                        <concept-count-pill class="d-flex align-center" [concept]="searchItem.concepto">
                        </concept-count-pill>
                    </li>
                </ul>


            </ng-container>
        </ng-container>
    </ng-container>
    <!-- <div class="toogle-float" (click)="toogleNavbar()" [style.left.px]="(appService.navbarState | async) ? 385 : 0">
        {{ (appService.navbarState | async) ? '<' : '>' }}
    </div> -->
</div>