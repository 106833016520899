/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./concept-stats.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./concept-stats.component";
import * as i4 from "../../../services/snomed.service";
var styles_AppConceptStatsComponent = [i0.styles];
var RenderType_AppConceptStatsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppConceptStatsComponent, data: {} });
export { RenderType_AppConceptStatsComponent as RenderType_AppConceptStatsComponent };
function View_AppConceptStatsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "contenedor-datos-principales"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "bloque-numerico-principal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Registros"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "hr", [["class", "divisor-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "bloque-numerico-secundario"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "article", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Exactos"])), (_l()(), i1.ɵeld(14, 0, null, null, 4, "article", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Descendientes"]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.total; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.ngIf.exact; _ck(_v, 11, 0, currVal_1); var currVal_2 = _v.context.ngIf.children; _ck(_v, 16, 0, currVal_2); }); }
export function View_AppConceptStatsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppConceptStatsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.snomed.stats(_co.concept.conceptId))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppConceptStatsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-concept-stats", [], null, null, null, View_AppConceptStatsComponent_0, RenderType_AppConceptStatsComponent)), i1.ɵdid(1, 49152, null, 0, i3.AppConceptStatsComponent, [i4.SnomedAPI], null, null)], null, null); }
var AppConceptStatsComponentNgFactory = i1.ɵccf("app-concept-stats", i3.AppConceptStatsComponent, View_AppConceptStatsComponent_Host_0, { concept: "concept" }, {}, []);
export { AppConceptStatsComponentNgFactory as AppConceptStatsComponentNgFactory };
