<ng-container *ngIf="type === 'attribute-group'">
    <svg:circle [attr.cx]="x" [attr.cy]="y" r="20" fill="white" stroke="black" stroke-with="2">
    </svg:circle>
</ng-container>
<ng-container *ngIf="type === 'conjunction'">
    <svg:circle [attr.cx]="x" [attr.cy]="y" r="10" fill="black" stroke="black" stroke-with="2"></svg:circle>
</ng-container>
<ng-container *ngIf="type === 'equivalent'">
    <svg:circle [attr.cx]="x" [attr.cy]="y" r="20" fill="white" stroke="black" stroke-with="2">
    </svg:circle>
    <svg:line [attr.x1]="x - 7" [attr.y1]="y - 5" [attr.x2]="x + 7" [attr.y2]="y - 5" stroke="black" stroke-width="2">
    </svg:line>
    <svg:line [attr.x1]="x - 7" [attr.y1]="y" [attr.x2]="x + 7" [attr.y2]="y" stroke="black" stroke-width="2">
    </svg:line>
    <svg:line [attr.x1]="x - 7" [attr.y1]="y + 5" [attr.x2]="x + 7" [attr.y2]="y + 5" stroke="black" stroke-width="2">
    </svg:line>
</ng-container>
<ng-container *ngIf="type === 'subsumed-by'">
    <svg:circle [attr.cx]="x" [attr.cy]="y" r="20" fill="white" stroke="black" stroke-with="2">
    </svg:circle>
    <svg:line [attr.x1]="x - 7" [attr.y1]="y - 8" [attr.x2]="x + 7" [attr.y2]="y - 8" stroke="black" stroke-width="2">
    </svg:line>
    <svg:line [attr.x1]="x - 7" [attr.y1]="y + 3" [attr.x2]="x + 7" [attr.y2]="y + 3" stroke="black" stroke-width="2">
    </svg:line>
    <svg:line [attr.x1]="x - 6" [attr.y1]="y - 8" [attr.x2]="x - 6" [attr.y2]="y + 3" stroke="black" stroke-width="2">
    </svg:line>
    <svg:line [attr.x1]="x - 7" [attr.y1]="y + 7" [attr.x2]="x + 7" [attr.y2]="y + 7" stroke="black" stroke-width="2">
    </svg:line> 
</ng-container>
<ng-container *ngIf="type === 'subsumes'">
    <svg:circle [attr.cx]="x" [attr.cy]="y" r="20" fill="white" stroke="black" stroke-with="2">
    </svg:circle>
    <svg:line [attr.x1]="x - 7" [attr.y1]="y - 8" [attr.x2]="x + 7" [attr.y2]="y - 8" stroke="black" stroke-width="2">
    </svg:line>
    <svg:line [attr.x1]="x - 7" [attr.y1]="y + 3" [attr.x2]="x + 7" [attr.y2]="y + 3" stroke="black" stroke-width="2">
    </svg:line>
    <svg:line [attr.x1]="x + 6" [attr.y1]="y - 8" [attr.x2]="x + 6" [attr.y2]="y + 3" stroke="black" stroke-width="2">
    </svg:line>
    <svg:line [attr.x1]="x - 7" [attr.y1]="y + 7" [attr.x2]="x + 7" [attr.y2]="y + 7" stroke="black" stroke-width="2">
    </svg:line>
</ng-container>


