/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.view.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../libs/ui/src/lib/navbar/navbar.component.ngfactory";
import * as i4 from "../../../../../../libs/ui/src/lib/navbar/navbar.component";
import * as i5 from "../../components/snomed-concept-search/snomed-search.component.ngfactory";
import * as i6 from "../../components/snomed-concept-search/snomed-search.component";
import * as i7 from "../../components/snomed-concept-search/snomed-search.service";
import * as i8 from "../../services/query-filter.service";
import * as i9 from "../../services/app.service";
import * as i10 from "../../services/users.service";
import * as i11 from "../../components/app-query-options/app-query-options.component.ngfactory";
import * as i12 from "../../components/app-query-options/app-query-options.component";
import * as i13 from "../../services/snomed.service";
import * as i14 from "@angular/router";
import * as i15 from "./main.view";
var styles_MainView = [i0.styles];
var RenderType_MainView = i1.ɵcrt({ encapsulation: 0, styles: styles_MainView, data: {} });
export { RenderType_MainView as RenderType_MainView };
export function View_MainView_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "main", [], [[2, "open", null]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ui-navbar", [], null, [[null, "itemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemClick" === en)) {
        var pd_0 = (_co.navClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NavBarComponent_0, i3.RenderType_NavBarComponent)), i1.ɵdid(3, 49152, null, 0, i4.NavBarComponent, [], { menuItems: [0, "menuItems"], active: [1, "active"] }, { itemClick: "itemClick" }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "snomed-search", [], null, null, null, i5.View_SnomedSearchComponent_0, i5.RenderType_SnomedSearchComponent)), i1.ɵdid(5, 49152, null, 0, i6.SnomedSearchComponent, [i7.SnomedSearchService, i8.QueryOptionsService, i9.AppService, i10.UserService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-query-options", [], null, null, null, i11.View_AppQueryOptionsComponent_0, i11.RenderType_AppQueryOptionsComponent)), i1.ɵdid(7, 49152, null, 0, i12.AppQueryOptionsComponent, [i13.SnomedAPI, i8.QueryOptionsService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i14.RouterOutlet, [i14.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.menuItems; var currVal_2 = _co.activeUrl; _ck(_v, 3, 0, currVal_1, currVal_2); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.appService.navbarState)); _ck(_v, 0, 0, currVal_0); }); }
export function View_MainView_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "andes-main-view", [], null, null, null, View_MainView_0, RenderType_MainView)), i1.ɵdid(1, 49152, null, 0, i15.MainView, [i9.AppService, i14.Router], null, null)], null, null); }
var MainViewNgFactory = i1.ɵccf("andes-main-view", i15.MainView, View_MainView_Host_0, {}, {}, []);
export { MainViewNgFactory as MainViewNgFactory };
