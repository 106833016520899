/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sanddance-view.view.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../concept-detail/navbar-detail/navbar-detail.component.ngfactory";
import * as i3 from "../concept-detail/navbar-detail/navbar-detail.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/common";
import * as i6 from "./sanddance-view.view";
import * as i7 from "../../services/snomed.service";
import * as i8 from "@angular/router";
var styles_AppSandDanceView = [i0.styles];
var RenderType_AppSandDanceView = i1.ɵcrt({ encapsulation: 0, styles: styles_AppSandDanceView, data: {} });
export { RenderType_AppSandDanceView as RenderType_AppSandDanceView };
function View_AppSandDanceView_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "fullscreen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 21, "app-navbar-detail-component", [], null, null, null, i2.View_AppNavbarDetailComponent_0, i2.RenderType_AppNavbarDetailComponent)), i1.ɵdid(3, 49152, null, 0, i3.AppNavbarDetailComponent, [], { concept: [0, "concept"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 17, "span", [["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(5, 1720320, null, 3, i4.NgbDropdown, [i1.ChangeDetectorRef, i4.NgbDropdownConfig, i5.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i4.ɵm]], null, null), i1.ɵqud(603979776, 2, { _menu: 0 }), i1.ɵqud(603979776, 3, { _menuElement: 0 }), i1.ɵqud(603979776, 4, { _anchor: 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "btn btn-info dropdown-toggle btn-sm mr-3 dropdown-toggle"], ["ngbDropdownToggle", ""], ["type", "button"]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i4.NgbDropdownToggle, [i4.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[4, 4]], i4.NgbDropdownAnchor, null, [i4.NgbDropdownToggle]), (_l()(), i1.ɵted(-1, null, [" Cambiar grafico "])), (_l()(), i1.ɵeld(13, 0, [[3, 0]], null, 8, "div", [["aria-labelledby", "dropdownMenuButton"], ["class", "dropdown-menu"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, [[2, 4]], 1, i4.NgbDropdownMenu, [i4.NgbDropdown], null, null), i1.ɵqud(603979776, 5, { menuItems: 1 }), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "dropdown-item"], ["ngbDropdownItem", ""]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeInsight("piramide") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, [[5, 4]], 0, i4.NgbDropdownItem, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, [" Piramide poblacional "])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "dropdown-item"], ["ngbDropdownItem", ""]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeInsight("pormes") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 16384, [[5, 4]], 0, i4.NgbDropdownItem, [i1.ElementRef], null, null), (_l()(), i1.ɵted(-1, null, [" Registros por mes "])), (_l()(), i1.ɵeld(22, 0, null, 0, 1, "button", [["class", "btn btn-outline-danger btn-sm"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cerrar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" X "])), (_l()(), i1.ɵeld(24, 0, [[1, 0], ["frame", 1]], null, 0, "iframe", [["src", "https://microsoft.github.io/SandDance/embed/v1/sanddance-embed.html"], ["style", "height:calc(100% - 58px); width:100%;border:none"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 5).isOpen(); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10).dropdown.isOpen(); _ck(_v, 9, 0, currVal_2); var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 14).dropdown.isOpen(); var currVal_5 = i1.ɵnov(_v, 14).placement; _ck(_v, 13, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵnov(_v, 17).disabled; _ck(_v, 16, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 20).disabled; _ck(_v, 19, 0, currVal_7); }); }
export function View_AppSandDanceView_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { element: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppSandDanceView_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.concept$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AppSandDanceView_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sanddance-view", [], null, null, null, View_AppSandDanceView_0, RenderType_AppSandDanceView)), i1.ɵdid(1, 4243456, null, 0, i6.AppSandDanceView, [i7.SnomedAPI, i8.ActivatedRoute, i1.ChangeDetectorRef, i5.Location], null, null)], null, null); }
var AppSandDanceViewNgFactory = i1.ɵccf("app-sanddance-view", i6.AppSandDanceView, View_AppSandDanceView_Host_0, {}, {}, []);
export { AppSandDanceViewNgFactory as AppSandDanceViewNgFactory };
