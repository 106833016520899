/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./concept-definition-status-icon.component";
import * as i2 from "../../services/snomed.service";
var styles_ConceptDefinitionStatusIconComponent = [".definition-status-icon[_ngcontent-%COMP%] {\n            display: inline-block; \n            font-weight: 700;\n            line-height: 1;\n            text-align: center;\n            white-space: nowrap;\n            vertical-align: baseline;\n            padding: 3px 7px;   \n            border-radius: 10px;\n            color: #856404;\n            background-color: #fff3cd;\n            border-color: #ffeeba;\n        }"];
var RenderType_ConceptDefinitionStatusIconComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ConceptDefinitionStatusIconComponent, data: {} });
export { RenderType_ConceptDefinitionStatusIconComponent as RenderType_ConceptDefinitionStatusIconComponent };
export function View_ConceptDefinitionStatusIconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "definition-status-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.concept.definitionStatus !== "PRIMITIVE") ? "\u2261" : "\u00A0\u00A0"); _ck(_v, 1, 0, currVal_0); }); }
export function View_ConceptDefinitionStatusIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "concept-definition-status-icon", [], null, null, null, View_ConceptDefinitionStatusIconComponent_0, RenderType_ConceptDefinitionStatusIconComponent)), i0.ɵdid(1, 49152, null, 0, i1.ConceptDefinitionStatusIconComponent, [i2.SnomedAPI], null, null)], null, null); }
var ConceptDefinitionStatusIconComponentNgFactory = i0.ɵccf("concept-definition-status-icon", i1.ConceptDefinitionStatusIconComponent, View_ConceptDefinitionStatusIconComponent_Host_0, { concept: "concept" }, {}, []);
export { ConceptDefinitionStatusIconComponentNgFactory as ConceptDefinitionStatusIconComponentNgFactory };
