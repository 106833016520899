/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../libs/snomed/src/lib/pipes/semtag.pipes";
import * as i3 from "../../../elements/concept-definition-status-icon/concept-definition-status-icon.component.ngfactory";
import * as i4 from "../../../elements/concept-definition-status-icon/concept-definition-status-icon.component";
import * as i5 from "../../../services/snomed.service";
import * as i6 from "./navbar-detail.component";
var styles_AppNavbarDetailComponent = [i0.styles];
var RenderType_AppNavbarDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppNavbarDetailComponent, data: {} });
export { RenderType_AppNavbarDetailComponent as RenderType_AppNavbarDetailComponent };
export function View_AppNavbarDetailComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SemTagPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "bar d-flex flex-row justify-content-between align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "d-flex flex-row align-items-center\n    "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [], [[8, "className", 0]], null, null, null, null)), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "concept-definition-status-icon", [], null, null, null, i3.View_ConceptDefinitionStatusIconComponent_0, i3.RenderType_ConceptDefinitionStatusIconComponent)), i1.ɵdid(6, 49152, null, 0, i4.ConceptDefinitionStatusIconComponent, [i5.SnomedAPI], { concept: [0, "concept"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "titulo-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "small", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SCTID:"])), (_l()(), i1.ɵted(13, null, [" ", " "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.concept; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "adi adi-", i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.concept)), "  mr-2"); _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.concept.fsn.term; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.concept.conceptId; _ck(_v, 13, 0, currVal_3); }); }
export function View_AppNavbarDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar-detail-component", [], null, null, null, View_AppNavbarDetailComponent_0, RenderType_AppNavbarDetailComponent)), i1.ɵdid(1, 49152, null, 0, i6.AppNavbarDetailComponent, [], null, null)], null, null); }
var AppNavbarDetailComponentNgFactory = i1.ɵccf("app-navbar-detail-component", i6.AppNavbarDetailComponent, View_AppNavbarDetailComponent_Host_0, { concept: "concept" }, {}, ["*"]);
export { AppNavbarDetailComponentNgFactory as AppNavbarDetailComponentNgFactory };
