<ng-container *ngIf="concept$ | async as concept">

    <app-navbar-detail-component [concept]="concept">
    </app-navbar-detail-component>
 
    <div class="p-1 d-flex">
        <input uiText [(ngModel)]="text" (write)="onChange($event)" debounce="400" [distinct]="true">
        <button class="btn btn-xs btn-info" type="button" (click)="getListado()">
            ASK 
        </button>
    </div>


    <div *ngIf="response" class="response">
        <div [innerHTML]="response">

        </div>
    </div>

    <div *ngIf="rawResponse">
        <h3> Respuesta cruda: </h3>
        {{ rawResponse | json }}
    </div>
</ng-container>