import { BehaviorSubject, Subject } from 'rxjs';
import { mergeObject, distincObject } from '../operators';
import { pluck, tap } from 'rxjs/operators';
import { SnomedHTTP } from '../../../../../libs/snomed/src/lib/services/snomed.http';
import { Router } from '@angular/router';
import { UserService } from './users.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../libs/snomed/src/lib/services/snomed.http";
import * as i2 from "@angular/router";
import * as i3 from "./users.service";
export class QueryOptionsService {
    constructor(snomed, router, userService) {
        this.snomed = snomed;
        this.router = router;
        this.userService = userService;
        this.INFERRED = '900000000000011006';
        this.STATED = '900000000000010007';
        this.data = {};
        this.filstrosParams = new Subject();
        this.conceptSelected = new BehaviorSubject(null);
        this.conceptSelected$ = this.conceptSelected.asObservable();
        this.filstrosParams$ = this.filstrosParams.asObservable().pipe(mergeObject(), distincObject(), tap((dto) => this.data = dto));
    }
    set(name, value) {
        this.filstrosParams.next({ [name]: value });
    }
    setAll(values) {
        const filtrosKey = ['prestacion', 'organizacion', 'profesional', 'localidad', 'sexo', 'ambito', 'turno'];
        filtrosKey.forEach((key) => {
            if (!values[key]) {
                values[key] = null;
            }
        });
        this.filstrosParams.next(values);
    }
    getFilter(name) {
        return this.filstrosParams$.pipe(pluck(name));
    }
    getValue(name) {
        return this.data[name];
    }
    selectConcept(concepto) {
        this.router.navigate(['concept', concepto.conceptId, 'detail'], { queryParamsHandling: 'preserve' });
        this.userService.registerConcepto(concepto).subscribe();
    }
    onConcept() {
        return this.conceptSelected$;
    }
}
QueryOptionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QueryOptionsService_Factory() { return new QueryOptionsService(i0.ɵɵinject(i1.SnomedHTTP), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.UserService)); }, token: QueryOptionsService, providedIn: "root" });
