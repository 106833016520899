import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { InjectionToken } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
// Constantes
const defaultOptions = { params: null, showError: true, showLoader: true };
export const BASE_URL = new InjectionToken('');
export class Server {
    constructor(http, baseURL) {
        this.http = http;
        this.baseURL = baseURL;
        this.authToken = window.sessionStorage.getItem('token');
    }
    setToken(token) {
        window.sessionStorage.setItem('token', token);
        this.authToken = token;
    }
    getToken() {
        return this.authToken;
    }
    parse(data) {
        let dateISO = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:[.,]\d+)?Z/i;
        let dateNet = /\/Date\((-?\d+)(?:-\d+)?\)\//i;
        const traverse = function (o, func) {
            for (let i of Object.keys(o)) {
                o[i] = func.apply(this, [i, o[i]]);
                if (o[i] !== null && typeof (o[i]) === 'object') {
                    traverse(o[i], func);
                }
            }
        };
        const replacer = function (key, value) {
            if (typeof (value) === 'string') {
                if (dateISO.test(value)) {
                    return new Date(value);
                }
                if (dateNet.test(value)) {
                    return new Date(parseInt(dateNet.exec(value)[1], 10));
                }
            }
            return value;
        };
        traverse(data, replacer);
        return data;
    }
    stringify(object) {
        return JSON.stringify(object);
    }
    prepareOptions(options) {
        const result = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                "Accept-Language": (options && options.params && options.params.language) || "es"
            }),
            params: options ? options.params : null
        };
        if (this.authToken) {
            result.headers = result.headers.set('Authorization', 'JWT ' + this.authToken);
        }
        return result;
    }
    getAbsoluteURL(url) {
        if (url.toLowerCase().startsWith('http')) {
            return url;
        }
        else {
            return this.baseURL + url;
        }
    }
    setBaseURL(baseURL) {
        this.baseURL = baseURL;
    }
    get(url, options = defaultOptions) {
        return this.http.get(this.getAbsoluteURL(url), this.prepareOptions(options));
    }
    post(url, body, options = null) {
        return this.http.post(this.getAbsoluteURL(url), this.stringify(body), this.prepareOptions(options));
    }
    put(url, body, options = defaultOptions) {
        return this.http.put(this.getAbsoluteURL(url), this.stringify(body), this.prepareOptions(options))
            .pipe(map((res) => this.parse(res.body)));
    }
    patch(url, body, options = defaultOptions) {
        return this.http.patch(this.getAbsoluteURL(url), this.stringify(body), this.prepareOptions(options))
            .pipe(map((res) => this.parse(res.text())));
    }
    delete(url, options = defaultOptions) {
        return this.http.delete(this.getAbsoluteURL(url), this.prepareOptions(options))
            .pipe(map((res) => this.parse(res.text())));
    }
}
Server.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Server_Factory() { return new Server(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_URL)); }, token: Server, providedIn: "root" });
