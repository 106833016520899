/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./auth.view";
import * as i2 from "@angular/router";
import * as i3 from "./auth.service";
var styles_AuthView = [];
var RenderType_AuthView = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthView, data: {} });
export { RenderType_AuthView as RenderType_AuthView };
export function View_AuthView_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["Login..."]))], null, null); }
export function View_AuthView_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auth-view", [], null, null, null, View_AuthView_0, RenderType_AuthView)), i0.ɵdid(1, 114688, null, 0, i1.AuthView, [i2.ActivatedRoute, i3.AuthService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthViewNgFactory = i0.ɵccf("app-auth-view", i1.AuthView, View_AuthView_Host_0, {}, {}, []);
export { AuthViewNgFactory as AuthViewNgFactory };
