import { Server } from '@andes-analytics/snomed';
import jwt_decode from "jwt-decode";
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../libs/snomed/src/lib/services/server.service";
export class AuthService {
    constructor(http) {
        this.http = http;
        this.http.setBaseURL(environment.API_URL);
    }
    login(token) {
        return this.http.post('/auth/login', { token }).pipe(tap((response) => this.http.setToken(response.token)));
    }
    isLoggin() {
        return !!this.http.getToken();
    }
    check(key) {
        const token = this.http.getToken();
        const metadata = jwt_decode(token);
        const permisos = metadata.permisos;
        const full = permisos.findIndex(p => p === 'analytics:*' || p === key);
        return full !== -1;
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.Server)); }, token: AuthService, providedIn: "root" });
