import { Server } from './server.service';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./server.service";
export class SnomedHTTP {
    constructor(http) {
        this.http = http;
        this.branch = 'MAIN/ODONTO/NEUQUEN-20200721-1318';
        this.snowstormPath = '/snowstorm';
    }
    load() {
        return this.http.get('/config.json').pipe(map(res => res.branch), tap(branch => this.branch = branch)).toPromise();
    }
    descriptions(params) {
        if (!params) {
            throw new Error('params is no set');
        }
        if (params.active === undefined) {
            params.active = true;
        }
        if (!params.language) {
            params.language = 'es';
        }
        if (params.conceptActive === undefined) {
            params.conceptActive = true;
        }
        return this.http.get(`${this.snowstormPath}/browser/${this.branch}/descriptions`, { params });
    }
    concept(sctid, params = {}) {
        return this.http.get(`${this.snowstormPath}/browser/${this.branch}/concepts/${sctid}`, { params });
    }
    parents(sctid, params) {
        return this.http.get(`${this.snowstormPath}/browser/${this.branch}/concepts/${sctid}/parents`, { params });
    }
    children(sctid, params) {
        return this.http.get(`${this.snowstormPath}/browser/${this.branch}/concepts/${sctid}/children`, { params });
    }
    analytics(query) {
        return this.http.post(`/andes/analytics/${query.visualization}`, query);
    }
    llm(query) {
        return this.http.post(`/andes/llm`, query);
    }
    cluster(sctid, semanticTags, asociacion) {
        return this.http.post(`/andes/rup/cluster`, {
            conceptId: sctid,
            semanticTags,
            tipoAsociacion: asociacion
        });
    }
    maps(sctid) {
        return this.http.post(`/andes/rup/maps`, { conceptId: sctid });
    }
    terms(sctid, filters) {
        const body = {
            target: `!${sctid}`,
            filter: filters,
            group: 'term'
        };
        return this.http.post(`/andes/analytics/count`, body).pipe(map(res => res[sctid]), map(terms => {
            return terms.map(item => {
                return {
                    term: item._id.term,
                    value: item.value.total
                };
            });
        }));
    }
    filtros(search) {
        return this.http.get('/andes/filtros', { params: search });
    }
    organizaciones() {
        return this.http.get('/andes/organizaciones', {});
    }
    conceptosNumerticos() {
        return this.http.get('/andes/conceptos-numericos', { params: {} });
    }
}
SnomedHTTP.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnomedHTTP_Factory() { return new SnomedHTTP(i0.ɵɵinject(i1.Server)); }, token: SnomedHTTP, providedIn: "root" });
