/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/angular-plotly.js/angular-plotly.js.ngfactory";
import * as i2 from "angular-plotly.js";
import * as i3 from "./histograma-charts.component";
var styles_AppHistogramaChartsView = [];
var RenderType_AppHistogramaChartsView = i0.ɵcrt({ encapsulation: 2, styles: styles_AppHistogramaChartsView, data: {} });
export { RenderType_AppHistogramaChartsView as RenderType_AppHistogramaChartsView };
export function View_AppHistogramaChartsView_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "plotly-plot", [], null, null, null, i1.View_PlotlyComponent_0, i1.RenderType_PlotlyComponent)), i0.ɵprd(512, null, i2.PlotlyService, i2.PlotlyService, []), i0.ɵdid(2, 1032192, null, 0, i2.PlotlyComponent, [i2.PlotlyService, i0.IterableDiffers, i0.KeyValueDiffers], { data: [0, "data"], layout: [1, "layout"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataGraph; var currVal_1 = _co.graph.boxplot; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AppHistogramaChartsView_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-histograma-chart-component", [], null, null, null, View_AppHistogramaChartsView_0, RenderType_AppHistogramaChartsView)), i0.ɵdid(1, 4767744, null, 0, i3.AppHistogramaChartsView, [], null, null)], null, null); }
var AppHistogramaChartsViewNgFactory = i0.ɵccf("app-histograma-chart-component", i3.AppHistogramaChartsView, View_AppHistogramaChartsView_Host_0, { data: "data" }, {}, []);
export { AppHistogramaChartsViewNgFactory as AppHistogramaChartsViewNgFactory };
