import { SnomedHTTP } from '@andes-analytics/snomed';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { bufferTime, filter, map, pluck, switchMap } from 'rxjs/operators';
import { cache } from '../operators';
import { QueryOptionsService } from './query-filter.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../libs/snomed/src/lib/services/snomed.http";
import * as i2 from "./query-filter.service";
export class SnomedAPI {
    constructor(api, qf) {
        this.api = api;
        this.qf = qf;
        this.cache = {};
        this.conceptBS = {};
        this.fromStats = new BehaviorSubject(null);
        this.fromStats$ = this.fromStats.asObservable();
        this.conceptosNumericos$ = this.api.conceptosNumerticos().pipe(cache());
        this.qf.filstrosParams$.subscribe(() => {
            this.conceptBS = {};
        });
        this.fromStats$.pipe(filter(concepts => concepts), bufferTime(250), filter(concepts => concepts.length > 0), switchMap(concepts => {
            return this.history(concepts);
        })).subscribe(() => { });
    }
    descriptions(params) {
        return this.api.descriptions(params);
    }
    concept(sctid, language = 'es') {
        return this.api.concept(sctid, { language });
    }
    parents(sctid) {
        const form = this.qf.getValue('relationship') || 'inferred';
        return this.api.parents(sctid, { form });
    }
    children(sctid, language = 'es') {
        const form = this.qf.getValue('relationship') || 'inferred';
        return this.api.children(sctid, { form, language });
    }
    stats(id) {
        if (!this.conceptBS[id]) {
            this.conceptBS[id] = new BehaviorSubject({ total: 0, exact: 0, children: 0 });
            this.fromStats.next(id);
        }
        return this.conceptBS[id];
    }
    getParams() {
        // [TODO] Filtros hardcoreados
        const start = this.qf.getValue('start');
        const end = this.qf.getValue('end');
        const organizacion = this.qf.getValue('organizacion') ? this.qf.getValue('organizacion').key : null;
        const profesional = this.qf.getValue('profesional') ? this.qf.getValue('profesional').key : null;
        const prestacion = this.qf.getValue('prestacion') ? this.qf.getValue('prestacion').key : null;
        const sexo = this.qf.getValue('sexo') ? this.qf.getValue('sexo').key : null;
        const localidad = this.qf.getValue('localidad') ? this.qf.getValue('localidad').key : null;
        const ambito = this.qf.getValue('ambito') ? this.qf.getValue('ambito').key : null;
        const turno = this.qf.getValue('turno') ? this.qf.getValue('turno').key : null;
        const type = this.qf.getValue('relationship') || 'inferred';
        return { start, end, organizacion, type, sexo, localidad, profesional, prestacion, ambito, turno };
    }
    history(sctids) {
        const filter = this.getParams();
        const reals = sctids.filter(c => !this.cache[c]);
        const body = {
            visualization: 'count',
            target: reals,
            filter
        };
        if (reals.length > 0) {
            return this.api.analytics(body).pipe(map(data => {
                const res = {};
                sctids.forEach(c => {
                    const value = data[c].value;
                    if (this.conceptBS[c]) {
                        this.conceptBS[c].next(value);
                    }
                    else {
                        this.conceptBS[c] = new BehaviorSubject(value);
                    }
                    if (this.cache[c]) {
                        res[c] = this.cache[c];
                    }
                    else {
                        res[c] = value;
                    }
                });
                return res;
            }));
        }
        else {
            const res = {};
            sctids.forEach(c => {
                res[c] = this.cache[c];
            });
            return of(res);
        }
    }
    demografia(type, sctid, rangoEtario) {
        const filter = this.getParams();
        const body = {
            visualization: type,
            target: sctid,
            filter: Object.assign({}, filter),
            group: ['sexo', rangoEtario]
        };
        return this.api.analytics(body).pipe(map(data => data[sctid]));
    }
    analytics(sctid, visualization, group = null, filters = {}, project = null) {
        const filter = Object.assign({}, this.getParams(), filters);
        const body = {
            visualization,
            target: sctid,
            filter,
            group,
            project
        };
        return this.api.analytics(body).pipe(map(data => data[sctid]));
    }
    llm(sctid, query, group = null, filters = {}, project = null) {
        const filter = Object.assign({}, this.getParams(), filters);
        const body = {
            target: sctid,
            filter,
            group,
            project,
            query
        };
        return this.api.llm(body);
    }
    cluster(sctid, semanticTags, asociacion) {
        return this.api.cluster(sctid, semanticTags, asociacion);
    }
    maps(sctid) {
        return this.api.maps(sctid);
    }
    terms(sctid) {
        const start = this.qf.getValue('start');
        const end = this.qf.getValue('end');
        const organizacion = this.qf.getValue('organizacion') ? this.qf.getValue('organizacion').key : null;
        return this.api.terms(sctid, {
            start,
            end,
            organizacion
        });
    }
    filtros(params) {
        return this.api.filtros(params);
    }
    organizaciones() {
        return this.api.organizaciones();
    }
}
SnomedAPI.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnomedAPI_Factory() { return new SnomedAPI(i0.ɵɵinject(i1.SnomedHTTP), i0.ɵɵinject(i2.QueryOptionsService)); }, token: SnomedAPI, providedIn: "root" });
export function getConceptOperator(activeRoute) {
    return combineLatest(activeRoute.paramMap.pipe(map((dto) => dto.params), pluck('id')), activeRoute.queryParams.pipe(pluck('language')));
}
