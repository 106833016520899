<div class="bar d-flex flex-row justify-content-between align-items-center">
    <div class="d-flex flex-row align-items-center
    ">
        <i class="adi adi-{{ concept | semtag  }}  mr-2"></i>
        <concept-definition-status-icon [concept]="concept"></concept-definition-status-icon>
        <div class="titulo-item">
            <strong> {{ concept.fsn.term }} </strong>
            <small>
                <strong>SCTID:</strong> {{ concept.conceptId }}
            </small>
        </div>
    </div>
    <div>
        <ng-content></ng-content>
    </div>
</div>