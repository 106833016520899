<ng-container *ngIf="concept$ | async as concept">
    <app-navbar-detail-component [concept]="concept"></app-navbar-detail-component>
    <section class="grid-detail">
        <main class="main-panel">
            <app-concept-stats [concept]="concept"></app-concept-stats>
            <ng-template #pillsTemplate let-concept="concept">
                <concept-count-pill [concept]="concept"></concept-count-pill>
            </ng-template>
            <snd-concept-desc-table [concept]="concept" [refSetLanguage]="refSetLanguage" [terms$]="term$">
            </snd-concept-desc-table>

            <div class="diagram-panel">
                <p class="title">Evolución temporal</p>
                <ng-container *ngIf="histograma$ | async as histograma">
                    <app-histograma-chart-component [data]="histograma"></app-histograma-chart-component>
                </ng-container>
            </div>


            <!-- Diagrama -->
            <div class="diagram-panel">
                <p class="title">Diagrama</p>
                <snd-concept-diagram [concept]="concept"></snd-concept-diagram>
            </div>

        </main>

        <!-- Ancestros & Descendientes -->
        <aside class="lateral-panel">
            <p class="title">Ancestros y descendientes</p>
            <snd-concept-parents [concept]="concept" [iconTemplate]="pillsTemplate" [language]="language"
                                 (conceptClick)="onSelected($event)">
            </snd-concept-parents>
            <snd-concept-childrens [concept]="concept" [iconTemplate]="pillsTemplate" [language]="language"
                                   (conceptClick)="onSelected($event)"></snd-concept-childrens>
        </aside>
    </section>
</ng-container>