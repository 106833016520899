<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="#">
        <svg style="width:100px;margin-right:10px" width="100%" height="100%" viewBox="0 0 3000 1051" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_111_211)"><path d="M532.258 19.8601L588.907 682.608H591.738L642.681 19.8601H842.301L702.161 1030.3H472.824L404.854 324.991H402.024L325.563 1030.3H101.932L0 19.8601H199.62L240.657 682.608H243.487L315.703 19.8601H532.304H532.258Z" fill="#CC4972"></path><path d="M867.727 1030.3V19.8601H1067.35V324.991H1452.85V19.8601H1652.46V1030.3H1452.85V740.998C1452.85 634.285 1366.57 547.798 1260.12 547.798C1153.67 547.798 1067.39 634.285 1067.39 740.998V1030.3H867.772H867.727Z" fill="#CC4972"></path><path d="M1706.15 184.506C1706.15 49.6958 1817.99 0 1941.14 0C2064.3 0 2176.14 49.65 2176.14 184.506V865.695C2176.14 1000.51 2064.3 1050.16 1941.14 1050.16C1817.99 1050.16 1706.15 1000.51 1706.15 865.695V184.506ZM1976.52 217.133C1976.52 188.762 1972.28 150.414 1941.14 150.414C1910.01 150.414 1905.77 188.762 1905.77 217.133V833.022C1905.77 861.394 1910.01 899.741 1941.14 899.741C1972.28 899.741 1976.52 861.394 1976.52 833.022V217.133Z" fill="#CC4972"></path><path d="M2347.41 19.8601H2612.13L2732.46 1030.3H2524.35L2501.7 755.001H2406.85L2375.71 1030.3H2197.37L2347.41 19.8601ZM2488.97 610.215L2462.08 195.809H2456.42L2423.87 610.215H2489.01H2488.97Z" fill="#CC4972"></path><path d="M2820.19 770.56V19.8601H2999.95V770.56H2820.19ZM2820.19 1030.25V820.21H2999.95V1030.25H2820.19Z" fill="#CC4972"></path></g><defs><clipPath id="clip0_111_211"><rect width="100%" height="100%" fill="white"></rect></clipPath></defs></svg>
        <!--
            <i class="adi adi-andes-marca"></i>
            <i class="adi adi-andes-acronimo"></i>
        -->
        <span class="text-info"> | Analytics</span>
    </a>

    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-between" [ngbCollapse]="isCollapsed">
        <div></div>
        <ul class="navbar-nav">
            <li class="nav-item" *ngFor="let menu of menuItems" [class.active]="menu.key === active">
                <span class="nav-link text-white" (click)="navClick(menu)"> {{ menu.label }} </span>
            </li>
        </ul>
    </div>
</nav>