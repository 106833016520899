<div class="row m-2">
    <div class="col">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th scope="col">Sexo</th>
                    <th scope="col" *ngFor="let i of escalas[rangoEtario]"> {{ i }} </th>
                    <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Masculino</td>
                    <td *ngFor="let j of indices" [class.click]="isClick" (click)="emit('masculino', j)">
                        {{ tableDemografia[0][j] | number }}
                    </td>
                    <td [class.click]="isClick" (click)="emit('masculino', -1)">
                        {{ tableDemografia[0][numCols] | number }}
                    </td>
                </tr>
                <tr>
                    <td>Femenino</td>
                    <td *ngFor="let j of indices" [class.click]="isClick" (click)="emit('femenino', j)">
                        {{ tableDemografia[1][j] | number }}
                    </td>
                    <td [class.click]="isClick" (click)="emit('femenino', -1)">
                        {{ tableDemografia[1][numCols] | number }}
                    </td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td *ngFor="let j of indices">
                        {{ tableDemografia[2][j] | number }}
                    </td>
                    <td> {{ tableDemografia[2][numCols]  | number }} </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>