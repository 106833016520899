<div class="row m-2">
    <div class="col-12">
        <table mat-table [dataSource]="dataSource" matSort class="table table-striped table-bordered mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                <td mat-cell *matCellDef="let element"> {{element.label}} </td>
                <td mat-footer-cell *matFooterCellDef><b>Total</b></td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> #Registros </th>
                <td mat-cell *matCellDef="let element"> {{element.total | number}} </td>
                <td mat-footer-cell *matFooterCellDef> {{ calcTotal('total') | number }} </td>
            </ng-container>

            <ng-container matColumnDef="exact">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> #Exactos </th>
                <td mat-cell *matCellDef="let element"> {{ element.exact | number }} </td>
                <td mat-footer-cell *matFooterCellDef> {{ calcTotal('exact') | number }} </td>
            </ng-container>
            <ng-container matColumnDef="pacientes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> #Pacientes </th>
                <td mat-cell *matCellDef="let element"> {{ element.pacientes | number }} </td>
                <td mat-footer-cell *matFooterCellDef> {{ calcTotal('pacientes') | number }} </td>
            </ng-container>
            <ng-container matColumnDef="ratio">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Registros/Pacientes </th>
                <td mat-cell *matCellDef="let element"> {{ element.ratio | number: '0.2' }} </td>
                <td mat-footer-cell *matFooterCellDef> {{ calcTotal('total') / calcTotal('pacientes') | number: '0.2' }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
    </div>
</div>